import React, { useState, useRef, useEffect } from "react";
import ReactMarkdown from 'react-markdown';

const ChatWindow = ({ messages, sendMessage, isLoading }) => {
  const [input, setInput] = useState("");
  const [files, setFiles] = useState([]);
  const fileInputRef = useRef(null);
  const messagesEndRef = useRef(null);
  const messagesContainerRef = useRef(null);

  const scrollToBottom = () => {
    try {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    } catch (error) {
      console.error("Scroll error:", error);
    }
  };

  // Scroll to bottom when messages change
  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  // Auto-scroll only if user is near bottom
  const handleScroll = () => {
    const container = messagesContainerRef.current;
    if (container) {
      const isNearBottom = container.scrollHeight - container.scrollTop - container.clientHeight < 100;
      if (isNearBottom) {
        scrollToBottom();
      }
    }
  };

  const SUPPORTED_TYPES = [
    'application/pdf',
    'image/jpeg',
    'image/png',
    'image/gif',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'text/plain'
  ];

  const handleFileChange = (e) => {
    try {
      const selectedFiles = Array.from(e.target.files);
      const validFiles = selectedFiles.filter(file => {
        if (!file) return false;
        
        if (file.size > 10 * 1024 * 1024) {
          alert(`File ${file.name} is larger than 10MB`);
          return false;
        }
        
        if (!SUPPORTED_TYPES.includes(file.type)) {
          alert(`File ${file.name} is not a supported type. Supported types: PDF, Images, Word documents, and text files.`);
          return false;
        }
        
        return true;
      });

      setFiles(prev => [...prev, ...validFiles]);
    } catch (error) {
      console.error("File processing error:", error);
      alert("Error processing files. Please try again.");
    }
  };

  const removeFile = (index) => {
    setFiles(prev => prev.filter((_, i) => i !== index));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (input.trim() || files.length > 0) {
      console.log('Sending files:', files);
      sendMessage(input, files);
      setInput("");
      setFiles([]);
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    }
  };


  const handleCopy = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      // Optional: You could add a temporary "Copied!" message here
    } catch (err) {
      console.error('Failed to copy text:', err);
    }
  };

  return (
    <div className="chat-window">
      <div 
        className="messages" 
        ref={messagesContainerRef}
        onScroll={handleScroll}
      >
        {messages.map((msg, idx) => (
          <div key={idx} className={`message ${msg.type}`}>
            <div className="message-content">
              <ReactMarkdown>{msg.text}</ReactMarkdown>
              <div className="message-footer">
                <button 
                  className="copy-button"
                  onClick={() => handleCopy(msg.text)}
                >
                  Copy text 📋
                </button>
              </div>
            </div>
          </div>
        ))}
        {isLoading && (
          <div className="message bot loading">
            <div className="typing-indicator">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        )}
        <div ref={messagesEndRef} />
      </div>
      <form onSubmit={handleSubmit} className="input-form">
        <div className="files-preview">
          {files.map((file, index) => (
            <div key={index} className="file-preview">
              <span className="file-name">{file.name}</span>
              <button
                type="button"
                onClick={() => removeFile(index)}
                className="remove-file"
              >
                ×
              </button>
            </div>
          ))}
        </div>
        <div className="input-group">
          <input
            type="text"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder="Type a message..."
            disabled={isLoading}
          />
          <div className="file-input-group">
            <input 
              type="file"
              onChange={handleFileChange}
              id="file-input"
              ref={fileInputRef}
              accept=".pdf,.doc,.docx,.txt,image/*"
              style={{ display: 'none' }}
              disabled={isLoading}
              multiple
            />
            <label 
              htmlFor="file-input" 
              className={`file-label ${isLoading ? 'disabled' : ''}`}
            >
              Add Files
            </label>
          </div>
          <button 
            type="submit" 
            disabled={isLoading || (!input.trim() && files.length === 0)}
          >
            {isLoading ? 'Sending...' : 'Send'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default ChatWindow;

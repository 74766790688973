import React, { useState } from "react";
import axios from "axios";
import ChatWindow from "./components/ChatWindow";
import Login from "./components/Login";

const App = () => {
  const [messages, setMessages] = useState([]);
  const [sessionId, setSessionId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const sendMessage = async (message, files = []) => {
    if (!message.trim() && files.length === 0) return;

    setIsLoading(true);
    const formData = new FormData();
    formData.append("message", message);
    
    // Add files to formData
    files.forEach(file => {
      formData.append('files', file);
    });
    
    if (sessionId) formData.append("session_id", sessionId);

    try {
      // Show user message immediately
      setMessages(prev => [...prev, { 
        type: "user", 
        text: message + (files.length > 0 ? `\n(${files.length} files attached)` : '')
      }]);

      const response = await axios.post(
        "http://18.216.214.255:8000/api/chat",
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          timeout: 30000 // 30 second timeout
        }
      );

      if (response.data.error) {
        throw new Error(response.data.error);
      }

      setMessages(prev => [...prev, { 
        type: "bot", 
        text: response.data.response 
      }]);
      
      if (!sessionId && response.data.session_id) {
        setSessionId(response.data.session_id);
      }
    } catch (error) {
      console.error("Error sending message:", error);
      let errorMessage = "Sorry, there was an error processing your request.";
      
      if (error.response?.data?.error) {
        errorMessage += ` (${error.response.data.error})`;
      }
      
      setMessages(prev => [
        ...prev,
        { 
          type: "bot", 
          text: errorMessage
        }
      ]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
    setMessages([]);
    setSessionId(null);
  };

  return (
    <div className="app">
      {!isAuthenticated ? (
        <Login onLogin={setIsAuthenticated} />
      ) : (
        <>
          <div className="header">
            <h1>Quick Care Internal Chatbot</h1>
            <button onClick={handleLogout} className="logout-button">
              Logout
            </button>
          </div>
          <ChatWindow 
            messages={messages} 
            sendMessage={sendMessage}
            isLoading={isLoading}
          />
        </>
      )}
    </div>
  );
};

export default App;
